<template>
    <div class="d-flex align-items-end justify-content-start mt-16">
        <img
                          :src="imageUrl ? imageUrl : require('@/assets/images/page-img/avatar.png')"
                          class="rounded-circle"
                          alt="user"
                          width="60px"
                      />
        <v-card
                      class="title_highlight_color text-white"
                      :max-width="maxWith"
                      :width="width"
                      style="border-radius: 32px 32px 32px 0px;"
                  >
                    <v-card-title>
                          <v-row>
                            <v-col cols="12">
                              <p class="m-0 font-weight-bold font-size-16 text-break pointer-cursor secondary-prosk text-white">
                                    {{ userName }}
                              </p>
                              <p class="description">{{ description }}</p>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-subtitle class="bottom-0 position-absolute w-100 text-white">
                      <small class="float-end ">{{ date }}</small> <small class="float-end ml-4">{{ hour }}</small>
                    </v-card-subtitle>
        </v-card>
    </div>
</template>

<script>
export default {
  name: 'TestimonialsCard',
  props: {
    userName: { type: String, default: '' },
    description: { type: String, default: '' },
    date: { type: String, default: '' },
    hour: { type: String, default: '' },
    cssClasses: { type: String, default: '' },
    maxWith: { type: String, default: '400' },
    width: { type: String, default: '100%' },
    imageUrl: { type: String }
  }
};
</script>
